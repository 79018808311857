<template>
<div class="page-block navigation">

    <div class="main-nav">
        <ul>
            <li><router-link :to="{ name: 'home', params: { lang: $route.params.lang}}">{{ strs["La Sira"][$route.params.lang] }}</router-link></li>
            <li><router-link :to="{ name: 'studio_hub', params: { lang: $route.params.lang}}">{{ strs["Studio"][$route.params.lang] }}</router-link></li>
            <li><router-link :to="{ name: 'consulting_hub', params: { lang: $route.params.lang}}">{{ strs["Consulting"][$route.params.lang] }}</router-link></li>
            <li><router-link :to="{ name: 'galerie_hub', params: { lang: $route.params.lang}}">{{ strs["Galerie"][$route.params.lang] }}</router-link></li>
            <li><router-link :to="{ name: 'radio_hub', params: { lang: $route.params.lang}}">{{ strs["Radio"][$route.params.lang] }}</router-link></li>
        </ul></div>
    <div class="sub-nav">
        <ul>
            <li v-for="item in navStore.subItems" :key="item.id">
                <router-link :to="`${ '/' + $route.params.lang + navStore.basePath + item.slug }`" v-html="item.title.rendered" />
            </li>
        </ul>
    </div>
</div>
</template>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 6em;
}

.router-link-active, .navigation a:hover, .router-link-exact-active {
    color: #8ebfd4;
}

.sub-nav ul {
    overflow: scroll;
}
</style>

<script setup>
import { useNav } from '@/stores/nav_store'

const navStore = useNav()
const strs = {
    "La Sira": {fr: "La Sira", en: "Sira"},
    "Studio": {fr: "Studios", en: "Studios"},
    "Consulting": {fr: "Consulting", en: "Consulting"},
    "Galerie": {fr: "Galerie", en: "Gallery"},
    "Radio": {fr: "Radio", en: "Radio"}
}

</script>